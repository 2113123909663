<template lang="pug" functional>
  svg(
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg")
    path(
      d="M13.3334 6.57215V2.14291C13.3334 1.32867 12.65 0.666504 11.8096 0.666504H4.19056C3.35018 0.666504 2.66675 1.32867 2.66675 2.14291V15.2656C2.66675 15.6896 3.16118 15.9211 3.48684 15.6498L8.00008 11.8887L12.5133 15.6498C12.839 15.9211 13.3334 15.6896 13.3334 15.2656V6.57215Z"
      fill="currentColor")

</template>

<script>
export default {
  name: 'IconBookmark'
}
</script>

<style lang="scss">
</style>
